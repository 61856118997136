import profile from './profile_ig.jpeg'
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className='profile' src={profile}  alt="profile"  />
        
        <a
          
          href="https://www.instagram.com/bricklexstudios/"
          target="_blank"
          rel="noopener noreferrer"
        >
			
			<div className='brick'>
				<div>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
				</div>
				<div>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
				</div>
			</div>
			<div className='brick'>
				<div>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
				</div>
				<div>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
					<span className="dot"></span>
				</div>
			</div>
			
			
		</a>
	<div>
		<span className='socials' title="Follow us on Instagram @bricklexstudios"><a href="https://www.instagram.com/bricklexstudios/"
          target="_blank"
          rel="noopener noreferrer">
			  <FontAwesomeIcon  icon={faInstagram}/></a></span>
		<span className='socials' title="Contact us at bricklexstudios@gmail.com"><a href="mailto: bricklexstudios@gmail.com"><FontAwesomeIcon  icon={faEnvelope}/></a></span>
	</div>
	  <p className='footer-text'> © 2021 bricklexstudios.com</p>
      </header>
    </div>
  );
}

export default App;
